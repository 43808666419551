import { GlobalStyle } from "./globalStyles";
import { lazy, Suspense } from "react";
import Header from "./components/Header/index";
import Home from "./pages/Home";
import ScrollToTop from "./components/ScrollToTop/index";
// const Home = lazy(() => import("./Pages/Home"));
// const Header = lazy(() => import("./components/Header/index"));
// // const Footer = lazy(() => import("./components/Footer/index"));
// const ScrollToTop = lazy(() => import("./components/ScrollToTop/index"));

function App() {
  return (
    <>
      <Suspense fallback={null}>
        <GlobalStyle />
        {/* Hi There! */}
        <ScrollToTop />
        <Header />
        <Home />
        {/* <Footer /> */}
      </Suspense>
    </>
  );
}

export default App;
