import styled from "styled-components";
import mission from "../../assets/about1.png";
import vision from "../../assets/about2.png";
import value from "../../assets/about4.png"

const AboutSection = styled.section`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  // margin-top:30px;
  font-family: 'Jost', sans-serif !important ;
  `;
const MainTitle = styled.h1`
// align-text:center;
position:relative;
color:#e0b03c;
// width:100%;
// text-decoration: underline;
// text-decoration-color:yellow;
margin-top:0.5rem;
font-family: 'Jost', sans-serif !important ;
@media only Screen and (max-width: 40em) {
  margin-top:1.2rem;
}
`;

const Main = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media only Screen and (max-width: 829px) {
    width: 100%;
    margin: 1rem calc(2rem + 2vw);
  }
  @media only Screen and (max-width: 40em) {
    align-items: center;
    margin: 0.1rem calc(0.2rem + 0.2vw);
  }
  font-family: 'Jost', sans-serif !important ;
`;
const Content = styled.div`
  display: flex;
  height: 40vh;
  justify-content: space-between;
  align-items: center;
  // background-color:#afcae5;
  @media only Screen and (max-width: 829px) {
    width: 100%;
    height:29vh;
    // background-color:blue;
  }
  @media only Screen and (max-width: 40em) {
    flex-direction: column;
    height:25vh;
    position:relative;
    // background-color:red;
  }
`;

const Images = styled.div`
  display: flex;
  height: 40vh;
  justify-content: center;
  align-content: center;
font-family: 'Jost', serif !important ;
 font-weight: 100;
  width: 50%;
  @media only Screen and (max-width: 829px) { 
    height:29vh;
  }
  @media only Screen and (max-width: 40em) {
    width: 88%;
    height:23vh;
    opacity:0.2;
    position:relative;
  }

`;
const Text = styled.h4`
width: 48%;
position: relative;
  font-size: calc(0.5rem + 0.9vw);
font-family: 'Jost', sans-serif !important ;
font-weight:normal;
  line-height: 1.5;
  padding: 3rem;
  color: black;
  b{
    padding-left:5px;
    border-left: 8px solid #e0b03c;}
  @media only Screen and (max-width: 820px) {
    font-size: calc(0.5rem + 1vw);
}
  @media only Screen and (max-width: 40em) {
        width:80%;
        margin-left:0.9rem;
        margin-right:0.9rem;
        padding: 1rem;
        margin-top: 0.5rem;
        text-align:center;
    position:absolute;
    color:black;
    b{border:none;}
    ul {
      list-style-type: none; 
  }
 font-size: calc(0.6rem + 0.9vw);
  }
`;

const UL = styled.ul`
list-style: none;
font-weight:normal;
`;
const About = () => {
  return (
    <AboutSection id="about">
      <MainTitle>
        <h1 style={{fontFamily: 'Jost'}}> About us</h1>
      </MainTitle>
       <Main>
        <Content>
          <Images>
            <img src={mission} alt="img" />
          </Images>
             <Text>
            <b>OUR MISSION</b><br/><br/>To be successfully grow and diversify business to the benefit of our customers and employees, creating value and profitability
            </Text>
        </Content>
        <Content>
        <Text>
        <b> OUR Vision</b><br/><br/>To be Africa's World Class Logistics Company
            </Text>
          <Images>
            <img src={vision} alt="img" />
          </Images>
        </Content>
        <Content>
           <Images>
            <img src={value} alt="img" />
          </Images>
            <Text>
            <b>OUR VALUE</b><br/><br/>
            <UL>
              <li >Passion for Delivery</li>
              <li>Forward Looking</li>
              <li>Winning</li>
              <li>Integrity</li>
            </UL>
       </Text>
        </Content>
      </Main>
    </AboutSection>
  );
};

export default About;
