import './anotherabout.css'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const AnotherAbout = () => {
  return (
    <Box sx={{display:"flex"}} >
    <Grid  container className='leftbox'> 
          <Grid item xs={2}>         
        </Grid>
    </Grid>
          <Grid container className='cards' rowSpacing={5}  columnSpacing={{ xs: 1 , sm: 1, md: 1, lg: 5 }} >
          <Grid className='firstPhara'item xs={12}  >
           <b className='maintitle'>Why choose us <br/><br/></b>
             <p  >We believe in our people. With a team of well experienced people, we are 
committed to providing flexible, tailor made offerings that will delight our 
customers. We have number of human resource programs coupled by our 
spiriting of 'winning' in place dedicated to keep our staff motivated, diligent 
and provide you with excellent customer service at all times. </p>
          </Grid >
    
          <Grid item xs={12} sm={6} md={6} lg={3} >
            < Card sx={{ minWidth: 200, maxWidth:300, minHeight: 300, height:350, maxHeight:350 }}>
     <CardContent style={{ paddingLeft:'30px'}}>
       <Typography gutterBottom variant="h7" component="div" style={{fontFamily:"'Jost', 'Merriweather', serif", fontWeight:"500" , color:'#e0b03c'}}>
         Managing Director
         <br/>
        <br/>
       </Typography>
       <Typography variant="body2" color="text.secondary" style={{overflow: "hidden",  fontFamily:" 'Jost','Merriweather', serif", color:'black'}}>         
      Responsible for the growth expansion and more specifically, increasing visibility and presence within the business community in
      the region.<br/>
       </Typography>
             </CardContent>
           </Card>
          </Grid>
           <Grid item xs={12} sm={6} md={6} lg={3} >
            < Card sx={{ minWidth: 200, maxWidth:300, minHeight: 300, height:350, maxHeight:350 }}>
     <CardContent  style={{ paddingLeft:'30px'}}> 
       <Typography gutterBottom variant="h7" component="div" style={{ fontFamily:" 'Jost','Merriweather', serif", fontWeight:"500" , color:"#e0b03c"}}>
              Sales & Marketing
        <br/>
        <br/>
       </Typography>
       <Typography variant="body2" color="text.secondary" style={{overflow: "hidden",   fontFamily:"'Jost', 'Merriweather', serif" , color:"black"}}>      
       Specialized and dedicated to specific industries like FMCG, Construction Industry and manufacturing Industries. 
       The Marketing team concentrates on different sectors and their core responsibility is to target new customers in this fast growing industry and create supply chain solutions.
       </Typography>
             </CardContent>
           </Card>
          </Grid>
           <Grid item xs={12} sm={6} md={6} lg={3} >
            < Card sx={{ minWidth: 200, maxWidth:300, minHeight: 300, height:350, maxHeight:350 }}>
     <CardContent  style={{ paddingLeft:'30px'}}>
       <Typography gutterBottom variant="h7" component="div" style={{ fontFamily:"'Jost', 'Merriweather', serif", fontWeight:"500" , color:"#e0b03c"}}>
         Finance Team
           
    <br/>
        <br/>
       </Typography>
       <Typography variant="body2" color="text.secondary" style={{overflow: "hidden",   fontFamily:"'Jost', 'Merriweather', serif", color:"black"}}>        
    Reporting directly to the Managing Director and is responsible for managing the overall development of the company. 
    The Finance team is Budgeting, Planning, Scheduling and Implementation of all projects and business development activities. The company will produce results oriented services.
       </Typography>
             </CardContent>
           </Card>
          </Grid>
           <Grid item xs={12} sm={6} md={6} lg={3} >
            < Card sx={{ minWidth: 200, maxWidth:300, minHeight: 300, height:350, maxHeight:350 }}>
     <CardContent  style={{ paddingLeft:'30px'}}>
       <Typography gutterBottom variant="h7" component="div" style={{ fontFamily:"'Jost', 'Merriweather', serif", fontWeight:"500" ,color:'#e0b03c'}}>
        Operations Manager
        <br/>
        <br/>
       </Typography>
       <Typography variant="body2" color="text.secondary" style={{overflow: "hidden",   fontFamily:"'Jost', 'Merriweather', serif", color:"black"}}>        
      Veteran in the logistics field heads the customs clearance and distribution operations. 
      The current facility efficient logistics and customs clearance facility that allows it to comprehensively fulfill the needs of both the customer and the company.
       </Typography>
             </CardContent>
           </Card>
          </Grid>
            </Grid>
            </Box>
      
  );
};

export default AnotherAbout;
