import * as React from 'react';
import './services.css'
import { styled , useTheme} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Index2 from './index2';

const steps = [
  {
    id:1,
    label: "Freight Forwarding",
    description: `Logiskills provides comprehensive and professional service for delivery of your cargo
    `,
    imgPath:  require('../../assets/freight.jpg'),
    httppath: 'https://www.bankofabyssinia.com/'
  },
  {
    id:2,
    label: "Customs Clearance & Security",
    description:
      `A well-designed and well-managed customs clearance program reduces cycle times, improves shipment visibility and lowers cost. Local experts of our Customs service enable timely and reliable movement of your goods through the customs process. We offer clearance in a consistent, timely and cost effective manner by acting as a direct extension of your business to develop individual service programs that are uniquely tailored to your needs. You are assigned a single point of contact that is trained to handle all of your customs clearance needs by licensed professionals to extent permitted by law and information system interfaces that are connected to custom authorities , Where available we provide translation and classification for documents also arrange survey of damaged cargo and obtain damage/loss reports to protect your righto make claims through underwrites.
      If your goods sit on a dock awaiting customs clearance for a day, your product flow advantage is squandered. Additionally incomplete or wrong customs documentation can cause considerable delays in transportation, reducing supply chain and delivery reliability.`,
    imgPath:  require('../../assets/customs.jpg')
  },
  {
    id:3,
    label: "Port Handling and Transportation",
    description: `We provide receiving, loading, discharge and delivery of motor vehicles, container `,
    imgPath: require('../../assets/port.png'),
    
  },
   {
     id:4,
    label: "Banking Processes",
    description: `Additionally we handle the banking processes from the letter of credit ...`,
    imgPath: require('../../assets/banking.jpg')
  },
  {
    id:5,
    label: "Shipping Division",
    description:
      "Through our shipping division, we handle all Ethiopian shipping lines processes ...",
    imgPath: require('../../assets/shipping.jpg')
  },
  {
    id:6,
    label: "Bonded Warehouse Logistics Services",
    description: `We further can arrange for door to door customs clearing and movement ...`,
    imgPath: require('../../assets/bonded-warehouse.jpg')
  }
];
export default function Index() {
  const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [visible, setVisible] = React.useState(false);
    const handleOpen = (id) => {
    setOpen(prevState => !prevState);
    setActiveStep(id)
  };
    const handleClose = () => {
    setOpen(!open);
    setVisible(!visible);
  };
  const [activeStep, setActiveStep] = React.useState(0);
 
  return (
    <div id="services" className='cardcontainer'>  
   {open ?
      <Index2 id={activeStep} handleOpen={handleOpen}/>
    :<Box className="boxcard"  sx={{ display: visible ? 'none' : 'block'}}>
         <div className='sevicetitle' >  
     <h1 > Our Services</h1>
      <h4> What we can offer you </h4> <br/></div>
      <Grid container rowSpacing={5}  columnSpacing={{ xs: 1 , sm: 1, md: 1, lg: 5 }}>
        {steps.map((user) => (
          <Grid item xs={12} sm={6} md={6} lg={4} key={user}  data-id={user.id}>
            < Card sx={{ minWidth: 200, maxWidth:450, minHeight: 300, maxHeight:400 }}>
     
     <CardMedia
       component="img"
       alt={user.label}
       height="140"
       style={{height:'180px'}}
       image={user.imgPath}
     />
     <CardContent>
       <div className='cardcontents' style={{borderLeft: "6px solid #e0b03c",  height:" 80px",paddingLeft:'10px'}}> 
       <p className=" label card" >
         {user.label}
       </p>
       <p className=" description card ">
         {user.description}
       </p>
         </div>
     </CardContent>
     <CardActions>
     <button onClick={() =>handleOpen(user.id) } className="morebutton" size="small">Read More</button>
    
     </CardActions>
    
   </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
}
    
    </div>
  );
}

