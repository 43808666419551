import React from "react";
import ReactMapBoxGl, { Layer, Feature, Marker} from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import marker from "../../assets/marker.png";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import "./index.css";

export default function App() {
    
  const coordinates=[38.7678, 9.0100];
  const zoom= [15];
  const Map = ReactMapBoxGl({
      
      accessToken: "pk.eyJ1IjoiZ2VsdTI0IiwiYSI6ImNremUxeHJkazB5ODQydW82bmNoc2hnYm0ifQ.8uXF5Kzxo-DK3lTy_353Mw",
      
  })
  console.log(process.env.REACT_APP_MAPBOX_TOKEN);
  return (
  <div>
      <div style={{ 
        width:'100vw'
      }}> 
    <div className="map">
      <div class="mapouter">
          <div class="gmap_canvas">
            <iframe  id="gmap_canvas" src="https://maps.google.com/maps?q=8.995288,%2038.759617&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
          </div>
        </div>
  </div>
  <div id= "contact" className="mainContainer">
      <Grid container spacing={3} id="container">
        <Grid item xs={12} md={3} >
          <div>
          <h4>Address</h4>
          <br />
          <p>Kirkos, Addis Ababa, Ethiopia</p>
          <p>Yeshitam Building, 6th floor</p>
          </div>
          
        </Grid>
        <Grid item xs={12} md={3} lg={6}>
          <div style={{width:'20vw'}}>
            <h4>Contact</h4>
          <br />
          <p>Tel: +251911410577</p>
          <p>Fax: +25111410577</p>
          <p>P.O.BOX: 20636</p>
          <p>Email: info@logiskillstransit.com</p>
          <p>      ermiasgirma@logiskillstransit.com</p>
          </div>
          
        </Grid>
        <Grid item xs={12} md={3}>
          <div className="icons">
          <div className="icon"><a href="mailto: info@logiskillstransit.com"><EmailIcon/> </a></div>
          <div className="icon"><a href="https://m.facebook.com/978127802304635/"><FacebookIcon/></a> </div>
          <div className="icon"><a href="https://www.linkedin.com/company/logiskills-transit-services" starget="_blank"><LinkedInIcon/></a></div>
          </div> 
          <p className="link" > Developed by  
          <a
          className="App-link"
          href="http://mbandevelopers.com"
          target="_blank"
          rel="noopener noreferrer"
        >  MBAN</a></p>
         </Grid>
      </Grid>        
  </div>
  </div>
</div>
  );
}