// This is HeroSection component, Main top section of website
import styled, { keyframes } from "styled-components";
import backgroundImg from "../../assets/car.jpg";
import arrow from "../../assets/Arrow Right.svg";

const move = keyframes`
0% { transform: translateY(-5px)  }
    50% { transform: translateY(10px) }
    100% { transform: translateY(-5px) }
`;
const ImageCon = styled.div`
width:100vw;
position: absolute;
height: 90vh;
// background: -webkit-gradient(linear, left top, right bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.7))), url(${backgroundImg})  no-repeat;
background-repeat: no-repeat;
background-image: linear-gradient(-25deg, rgba(0,0,0,1)-15%, rgba(0,0,0,0)75%, rgba(255,255,255,1) 115%), url(${backgroundImg}); 
background-size: cover;
@media only Screen and (max-width: 829px) {
  height: 100%;
  // top:-3;
}
@media only Screen and (max-width: 40em) {
  height: 100%;
  top:0;
}
`;
const Image =  styled.img`
width:100%;
height: auto;
`;
const HomeSection = styled.section`
  width: 100vw;
  height: 90vh;
  display: flex;
  justify-content: center;
  position: relative;
  @media only Screen and (max-width: 829px  ) {
    height: 60vh;
    display: block;
  }
  @media only Screen and (max-width: 40em) {
    height: 50vh;
    padding-bottom: 2rem;
  }
`;

const MainContent = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  width: 100%;
  // float:left;
  // z-index: 7;
  animation: ${move} 2.5s ease infinite;
  @media only Screen and (max-width: 829px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height:100%;
  }
`;
const Lb = styled.div`
// background-color: #ffffff;
// opacity:0.8;
  display: flex;
  // left: 200px;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  line-height: 1.5;
  color: var(--white);
  position: relative;
  // z-index: 15;
  padding: 2rem;
  @media only Screen and (max-width: 829px) {
    width: 80%;
    left:0;
    text-align: center;
    align-items: center;
    justify-content: space-around;
    // filter: drop-shadow(2px 4px 6px black);
  }
  @media only Screen and (max-width: 40em) {
    // filter: none;
    // background-color: #fedb85;
    // opacity:0.8;
    // padding: 0.2rem;
    // margin-top: calc(1rem + 1vw);
  }
`;

const Topic = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--nav);
  color: var(--white);
  font-weight: 700;
  font-size: calc(0.4rem + 0.4vw);
  padding: 0.5rem 1rem;
  margin: 1rem 0rem;
  border-radius: 20px;
  @media only screen and (max-width: 40em){
 display:none;
  }
`;

const Circle = styled.span`
  display: inline-block;
  font-family: 'Jost', serif;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #e0b03c;
  margin-right: 0.5rem;
`;

const Title = styled.h1`

  font-size: calc(1rem + 1vw);
  font-family: 'Jost', serif;
  line-height: 1.2;
  // text-align: center; 
  padding: 1rem 0rem;
  color: #e0b03c;
  b{
    color:#447aaf;
  }
  // background-color:rgba(255,255,255,0.5)
`;

const SubText = styled.h5`
  font-size: calc(0.5rem + 0.5vw);
  // text-align: center; 
  color: white;
  font-family: 'Inter', serif;
  @media only screen and (max-width: 829px){
    font-weight: 700;
    text-align: center; 
    // background-color: #fedb85;
    // opacity:0.8;
    // color:black;
    font-size: calc(1rem + 0.4vw);
     }
     @media only screen and (max-width: 40em){
      font-size: calc(0.6rem + 0.5vw);
       }
`;

const CTA = styled.button`
  background-color: #e0b03c;
  color: black;
  padding: 0.5rem 1rem;
  margin: 1rem 5rem 3rem 0rem;
  position:relative;
  border-radius: 20px;
  cursor: pointer;
  font-size: calc(0.5rem + 0.5vw);
  font-weight: 700;
  display: flex;
  align-items: center;
  transition: transform 0.2s;
font-family: 'Jost', serif;
  img {
    margin-left:0.3rem;
    color: #447aaf;
    width: 0.8rem;
  }
  @media only screen and (max-width: 829px) {
    margin:1rem 1rem 1rem 1rem;
    padding: 0.2rem 1rem;
    font-size: calc(0.7rem + 0.5vw);
  }
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }
  @media only screen and (max-width: 40em){
 display:none;
  }
`;

const scrollUp = (id, e) => {
  e.preventDefault();
  const element = document.getElementById(id);
  console.log(element)
  element.scrollIntoView({
    behavior: "smooth",
    block: "end",
    inline: "nearest",
  });
};

const HeroSection = () => {
  return (
    <HomeSection id="home">
    
      <ImageCon>
      <Image img={backgroundImg}  />
      </ImageCon>
    
      <MainContent id="home">
     
        <Lb id="leftBlock">
          <Topic>
            <Circle />
            <span>The Company</span>
          </Topic>
          <Title><b>Logiskills</b> Transit service</Title>
          <SubText>
            Logiskills Transit Service was founded in 2008 in Ethiopia which is a customs clearing and logistics services provider that moves business and industry through innovation, inspiration and foresight.
          </SubText>
          <CTA onClick={(e) => scrollUp("contact", e)}>
            Learn More
          </CTA>
        </Lb>
      </MainContent>
    </HomeSection>
  );
};

export default HeroSection;
