import "./OurClients.css";
import { useState } from "react";
import Slider from "react-slick";

import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

const images2=[
  {
  imgPath:  require('../../assets/ab.jpg'),
    httppath: 'https://www.bankofabyssinia.com/',
    name:"Bank of Abssinia"
  },
  {
    imgPath:  require('../../assets/amc.png'),
    httppath: 'http://www.amcethiopia.com//',
    name:"American Medical Center"
  },
  {
 imgPath:  require('../../assets/dafa1.png'),
    httppath: 'https://dafa-group.com/en/',
    name:"DAFA"
  },
  {
 imgPath:  require('../../assets/ienet.png'),
    httppath: 'https://www.ienetworksolutions.com/',
    name:"IE Network Solutions"
  },
  {
 imgPath:  require('../../assets/img1.png'),
    httppath: 'https://www.yoyojuice.com//',
    name:"YoYo Juice"
  }
]

function OurClients() {
  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };

  const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
  };

  return (
    <div className="Clients" >
       <h1>Our Clients <br/></h1>
      <h4>Partners with more than 20 companies in the market<br/><br/></h4>
      <Slider {...settings}>
        {images2.map((img, idx) => (
          <div className={idx === imageIndex ? "slide activeSlide" : "slide"}>
            <a  href={img.httppath}  target="_blank" >
            <img src={img.imgPath} alt={img} />
            <h2 style={{ fontFamily: "'Jost'",fontWeight:"normal"}}>{img.name}</h2>
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default OurClients;
