import React from 'react'
import './services.css'
import { useTheme} from '@mui/material/styles';
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function Index2({ id, handleOpen}) {
    const steps = [
  {
    id:1,
    label: "Freight Forwarding",
    description: `Logiskills provides comprehensive and professional service for delivery of your cargo
    `,
    imgPath:  require('../../assets/freight.jpg'),
    
  },
  {
    id:2,
    label: "Customs Clearance & Security",
    description:
      `A well-designed and well-managed customs clearance program reduces cycle times, improves shipment visibility and lowers cost. Local experts of our Customs service enable timely and reliable movement of your goods through the customs process. We offer clearance in a consistent, timely and cost effective manner by acting as a direct extension of your business to develop individual service programs that are uniquely tailored to your needs. You are assigned a single point of contact that is trained to handle all of your customs clearance needs by licensed professionals to extent permitted by law and information system interfaces that are connected to custom authorities , Where available we provide translation and classification for documents also arrange survey of damaged cargo and obtain damage/loss reports to protect your righto make claims through underwrites.
      If your goods sit on a dock awaiting customs clearance for a day, your product flow advantage is squandered. Additionally incomplete or wrong customs documentation can cause considerable delays in transportation, reducing supply chain and delivery reliability.`,
    imgPath:  require('../../assets/customs.jpg')
  },
  {
    id:3,
    label: "Port Handling and Transportation",
    description: `We provide receiving, loading, discharge and delivery of motor vehicles, container `,
    imgPath: require('../../assets/port.png'),
    
  },
   {
     id:4,
    label: "Banking Processes",
    description: `Additionally we handle the banking processes from the letter of credit ...`,
    imgPath: require('../../assets/banking.jpg')
  },
  {
    id:5,
    label: "Shipping Division",
    description:
      "Through our shipping division, we handle all Ethiopian shipping lines processes ...",
    imgPath: require('../../assets/shipping.jpg')
  },
  {
    id:6,
    label: "Bonded Warehouse Logistics Services",
    description: `We further can arrange for door to door customs clearing and movement ...`,
    imgPath: require('../../assets/bonded-warehouse.jpg')
  }
];
const theme = useTheme();
 const [activeStep, setActiveStep] = React.useState(id-1);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
     <Box className= 'mainbox'>
        <div className='sevicetitle'>  
     <h1 > Our Services</h1>
      <h4> What we can offer you </h4> <br/></div>  
      <Box component="img" className='boximg'
        src={steps[activeStep].imgPath}
        alt={steps[activeStep].label}
      />
      <MobileStepper
        steps={maxSteps}
        position="static"
          sx={{ 
          maxWidth: '100%',
          width: "100%"
        }}
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
           
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            
          </Button>
        }
      />
       <Box sx={{ height: '50%', maxWidth: '100%', width: "100%", p: 2 }}>
       <div className="verticalline" style={{}}> 

        <Typography className='label' style={{ }}>{steps[activeStep].label}</Typography>
        <br/>
        <p className='description' > {steps[activeStep].description}</p>
        </div>
         <button className='lessbutton' onClick={() => handleOpen(id)} >Read Less</button>
 
      </Box>
    </Box>
  )
}
